<script lang="ts" setup>
import type { CmsBlockLastSeenProductsSlider } from '~/types/models/cms/cmsBlockTypes';
const props = defineProps<{
    content: CmsBlockLastSeenProductsSlider;
}>();
const { getSlotContent } = useCmsBlock(props.content);
const slotContent = getSlotContent('content');
</script>
<template>
    <div class="cms-block-last-seen-products-slider">
        <CmsGenericElement :content="slotContent" />
    </div>
</template>
